import axios from "axios";
import api from "@/api";
import router from "@/router";
// import i18n from "../../locales/i18n";

const businesses = {
  state: {
    businesses: [],
    selectedBusiness: null,
    businessTokens: null,
  },
  mutations: {
    setCurrentSidebarBusinessButton(state, payload) {
      state.currentSidebarBusinessButton = payload;
    },
    toggleSidebarBusinessButtons(state, id) {
      for (
        let index = 0;
        index < state.sidebarBusinessButtons.length;
        index++
      ) {
        const element = state.sidebarBusinessButtons[index];
        if (element.id === id) {
          state.sidebarBusinessButtons[index].active = true;
          router.push({ name: element.url });
        } else {
          state.sidebarBusinessButtons[index].active = false;
        }
      }
      this.commit("setCurrentSidebarBusinessButton", id);
    },
    setBusinesses(state, payload) {
      state.businesses = payload;
    },
    setSelectedBusiness(state, payload) {
      state.selectedBusiness = payload;
    },
    setBusinessTokens(state, payload) {
      state.businessTokens = payload;
    },
  },
  actions: {
    fetchBusinesses({ commit, rootState }) {
      return new Promise((resolve) => {
        axios.get(`${api}/r/app/v1/user-businesses`, rootState.auth).then(
          (response) => {
            commit("setBusinesses", response.data);
            resolve(response);
          },
          (error) => {
            resolve(error.response);
          }
        );
      });
    },
    createBusiness({ rootState }, payload) {
      return new Promise((resolve) => {
        axios.post(`${api}/v1/businesses`, payload, rootState.authFd).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            resolve(error.response);
          }
        );
      });
    },
    updateBusiness({ rootState }, payload) {
      // para editar la información del restaurante
      return new Promise((resolve) => {
        axios
          .put(
            `${api}/v1/businesses/${payload.businessId}`,
            payload.businessInfo,
            rootState.authFd
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
    fetchBusinessTokens({ commit, rootState }, payload) {
      return new Promise((resolve) => {
        axios
          .post(
            `${api}/r/app/login/obtain-token`,
            { business_id: payload.id },
            rootState.auth
          )
          .then(
            (response) => {
              commit("setBusinessTokens", response.data);
              commit("setDomainName", payload);
              commit("setSelectedBusiness", payload);
              resolve(response);
            },
            (error) => {
              resolve(error);
            }
          );
      });
    },
    refreshBusinessToken({ commit, rootState }) {
      return new Promise((resolve) => {
        axios
          .post(`${api}/r/${rootState.domainName}/auth/login/refresh`, {
            refresh: rootState.businessRefreshToken,
          })
          .then(
            (response) => {
              commit("setBusinessTokens", response.data);
              resolve(response);
            },
            (error) => {
              resolve(error.response);
            }
          );
      });
    },
    businessLogout({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/r/${rootState.domainName}auth/logout_tenant`,
            {
              business_refresh_token: rootState.businessRefreshToken,
              domain: rootState.domainName,
            },
            rootState.businessAuth
          )
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              resolve(error.response);
              reject(error);
            }
          );
      });
    },
  },
  getters: {
    getBusinesses(state) {
      return state.businesses;
    },
    getCurrentSidebarBusinessButton(state) {
      return state.currentSidebarBusinessButton;
    },
    getSidebarBusinessButtons(state) {
      return state.sidebarBusinessButtons;
    },
    getSelectedBusiness(state) {
      return state.selectedBusiness;
    },
    getBusinessTokens(state) {
      return state.businessTokens;
    },
  },
};

export default businesses;
