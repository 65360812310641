import api from "@/api";
import axios from "@/businessApi";
import { format } from "date-fns";
// import getToday from "@/helpers/getToday";

export const regenerateContent = ({ rootState }, tag) => {
  return new Promise((resolve) => {
    axios
      .get(
        `https://www.${rootState.domainName}.net/api/regenerate?tag=${tag}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const createEvent = ({ rootState }, payload) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${api}/r/${rootState.domainName}/v1/events`,
        payload,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const editEvent = ({ rootState }, payload) => {
  const id = payload.get("id");
  return new Promise((resolve) => {
    axios
      .patch(
        `${api}/r/${rootState.domainName}/v1/events/${id}`,
        payload,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchEvents = ({ rootState }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api}/r/${rootState.domainName}/v1/events`, rootState.businessAuth)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error.response);
        }
      );
  });
};
export const fetchEventsDetail = ({ commit, rootState }, eventSlug) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/events/get_event?slug=${eventSlug}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setEventsDetail", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchSubscribers = ({ commit, rootState }) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/clients/clients_marketing`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setSubscribersData", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchNotificationsWaiterBill = ({ rootState }) => {
  //modificar esto para implementar la ruta que trae las notifications de waiter

  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/general-analytics`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const updateStateNotifications = ({ rootState }, payload) => {
  return new Promise((resolve) => {
    axios
      .patch(
        `${api}/r/${rootState.domainName}/v1/general-analytics`,
        payload,
        rootState.businessAuth
      )
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchTotalOrders = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders/get_total_order?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotal", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotalCard = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders/get_total_card?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotalCard", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotaltransfer = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders/get_total_transfer?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotalTransfer", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTodayOrders = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTodayOrders", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotalCash = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders/get_total_cash?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotalCash", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotalTip = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders/get_total_tip?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTodayTip", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotalDebit = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders/get_total_card_debit?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotalDebit", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotalCredit = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/orders/get_total_card_credit?date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setTotalCredit", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchTotalBillCalls = ({ commit, rootState }, payload) => {
  return new Promise((resolve) => {
    const date = format(payload.date || payload.comparisonDate, "yyyy-MM-dd");
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/general-analytics/analitycs_type?type_filter=bill&timeframe=${payload.timeframe}&start_date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          if (payload.date) {
            commit("setTotalBillCalls", response);
          } else {
            commit("setComparisionTotalBillCalls", response);
          }
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchTotalWaiterCalls = ({ commit, rootState }, payload) => {
  return new Promise((resolve) => {
    const date = format(payload.date || payload.comparisonDate, "yyyy-MM-dd");
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/general-analytics/analitycs_type?type_filter=waiter&timeframe=${payload.timeframe}&start_date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          if (payload.date) {
            commit("setTotalWaiterCalls", response);
          } else {
            commit("setComparisionTotalWaiterCalls", response);
          }

          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
// localhost:8002/r/manganiello/v1/session-analitics/get_total_sessions?date=2023-07-30
export const fetchTotalSessions = ({ commit, rootState }, payload) => {
  return new Promise((resolve) => {
    const date = format(payload.date || payload.comparisonDate, "yyyy-MM-dd");

    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/general-analytics/analitycs_type?type_filter=sessions&timeframe=${payload.timeframe}&start_date=${date}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          if (payload.date) {
            commit("setTotalSessions", response);
          } else {
            commit("setComparisionTotalSessions", response);
          }

          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};

export const fetchMostClicked = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/query-products/get_most_clicked_products?date=${date}&branch=${rootState.currentBranch.id}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setMostClicked", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchGoogleAnalitycs = ({ commit, rootState }, timeframe) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/google-reviews/analitycs?timeframe=${timeframe}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setGoogleAnalitycs", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
export const fetchLeastClicked = ({ commit, rootState }, date) => {
  return new Promise((resolve) => {
    axios
      .get(
        `${api}/r/${rootState.domainName}/v1/query-products/get_least_clicked_products?date=${date}&branch=${rootState.currentBranch.id}`,
        rootState.businessAuth
      )
      .then(
        (response) => {
          commit("setLeastClicked", response);
          resolve(response);
        },
        (error) => {
          resolve(error.response);
        }
      );
  });
};
